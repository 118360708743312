<template>
  <b-dropdown-form
    class="py-1 dropdown-filter-form-n"
  >
    <b-row>
      <b-col cols="10">
        <p>Filters</p>
      </b-col>
      <b-col cols="2">
        <feather-icon
          icon="XIcon"
          size="16"
          class="align-right"
          @click="$emit('emitHideFilter')"
        />
      </b-col>
    </b-row>
    <b-form-group
      label="Activity Type"
      @click.native.stop
    >
      <v-select
        v-model="activityTypeValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="typeName"
        placeholder="Select from list"
        multiple
        :options="activityTypeOptions"
        :clearable="false"
        :reduce="typeName => typeName._id"
      />
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group
      label="Appt. Time"
    >
      <b-input-group>
        <flat-pickr
          v-model="startDateValue"
          class="form-control"
          placeholder="Select Date Range"
          :config="flatPickrConfig"
        />
        <b-input-group-append is-text>
          <feather-icon
            icon="CalendarIcon"
            class="cursor-pointer"
            data-toggle
            size="18"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <div v-if="$route.params.type == 'all-booking'">
      <hr class="dividerHR filter-divider">
      <b-form-group label="Status">
        <b-form-checkbox
          v-for="option in statusOptions"
          :key="option.value"
          v-model="statusFilterValue"
          :value="option.value"
          name="flavour-3a"
        >
          {{ option.label }}
        </b-form-checkbox>
      </b-form-group>
    </div>
    <b-button
      variant="outline-primary"
      size="sm"
      class="mr-1"
      @click="clearFilter"
    >
      Clear All
    </b-button>
    <b-button
      variant="primary"
      size="sm"
      @click="applyFilter"
    >
      Apply Filter
    </b-button>
  </b-dropdown-form>
</template>

<script>
import {
  BDropdownForm, BFormGroup, BButton, BRow, BCol, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BButton,
    BDropdownForm,
    BFormGroup,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    vSelect,
    flatPickr,
  },
  props: {
    statusFilter: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    startDate: {
      type: [String, null],
      default: null,
    },
    activityType: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    activityTypeOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      startDateValue: this.startDate,
      statusFilterValue: JSON.parse(JSON.stringify(this.statusFilter)) || [],
      activityTypeValue: JSON.parse(JSON.stringify(this.activityType)) || [],
      callNatures: [],
      flatPickrConfig: {
        wrap: true, mode: 'range', dateFormat: 'd/m/Y', enableTime: false, static: true, disableMobile: true,
      },
    }
  },
  watch: {
    startDate: {
      immediate: true,
      handler(val) {
        this.startDateValue = val
      },
    },
    statusFilter: {
      immediate: true,
      handler(val) {
        this.statusFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    activityType: {
      immediate: true,
      handler(val) {
        this.activityTypeValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
  },
  methods: {
    showAgainDropdown() {
      if (this.startDateValue) {
        this.$emit('emitShowFilter')
      }
    },
    applyFilter() {
      this.$emit('update:startDate', this.startDateValue)
      this.$emit('update:statusFilter', this.statusFilterValue)
      this.$emit('update:activityType', this.activityTypeValue)

      this.$emit('emitHideFilter')
    },
    clearFilter() {
      this.startDateValue = ''
      this.statusFilterValue = []
      this.activityTypeValue = []
      this.$emit('update:startDate', this.startDateValue)
      this.$emit('update:statusFilter', this.statusFilterValue)
      this.$emit('update:activityType', this.activityTypeValue)

      this.$emit('emitHideFilter')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
