<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Bookings
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <b-col
          class="content-header-right mb-2 ml-auto"
          cols="12"
          md="12"
        >
          <b-button
            variant="success"
            :to="{ name: 'appointment-bookings-create' }"
            class="mobile_create_button"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle d-md-block d-none">Create New Booking</span>
            <span class="align-middle d-md-none d-block">New</span>
          </b-button>
        </b-col>
        <b-dropdown
          v-if="isMobile()"
          :text="currentTabTitle"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="(tab, index) in pageTabs"
            :key="index"
            @click="changeRoute(tab.param)"
          >
            {{ tab.title }} ({{ tab.count }})
          </b-dropdown-item>
        </b-dropdown>
        <b-tabs
          pills
          class="booking__content"
          :nav-class="[isMobile() ? 'd-none' : '']"
        >
          <!-- Tab: Account -->
          <b-tab
            :active="$route.params.type == 'pending-approval'"
            lazy
            @click="changeRoute('pending-approval')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Pending Approval <span class="rounded-number">{{ pendingApprovalCount }}</span></span>
            </template>
            <pending-approval
              class="mt-2 pt-75"
              @emitCountBooking="countBookings"
            />
          </b-tab>

          <!-- Tab: Information -->
          <b-tab
            :active="$route.params.type == 'pending-payment'"
            lazy
            @click="changeRoute('pending-payment')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Pending Payment <span class="rounded-number">{{ pendingPaymentCount }}</span></span>
            </template>
            <pending-payment
              class="mt-2 pt-75"
              @emitCountBooking="countBookings"
            />
          </b-tab>

          <!-- Tab: Social -->
          <b-tab
            :active="$route.params.type == 'waiting-list'"
            lazy
            @click="changeRoute('waiting-list')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Waiting List <span class="rounded-number">{{ waitingListCount }}</span></span>
            </template>
            <waiting-list
              class="mt-2 pt-75"
              @emitCountBooking="countBookings"
            />
          </b-tab>

          <b-tab
            :active="$route.params.type == 'confirmed'"
            lazy
            @click="changeRoute('confirmed')"
          >
            <template #title>
              <span class="d-none d-sm-inline">Confirmed <span class="rounded-number">{{ confirmedCount }}</span></span>
            </template>
            <confirmed-list
              class="mt-2 pt-75"
              @emitCountBooking="countBookings"
            />
          </b-tab>

          <b-tab
            :active="$route.params.type == 'all-booking'"
            lazy
            @click="changeRoute('all-booking')"
          >
            <template #title>
              <span class="d-none d-sm-inline">All Bookings <span class="rounded-number">{{ allCount }}</span></span>
            </template>
            <all-booking
              class="mt-2 pt-75"
              @emitCountBooking="countBookings"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BButton, BCol, BTab, BTabs, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AllBooking from './all-booking/AllBooking.vue'
import PendingPayment from './pending-payment/PendingPayment.vue'
import PendingApproval from './pending-approval/PendingApproval.vue'
import ConfirmedList from './confirmed/ConfirmedList.vue'
import WaitingList from './waiting-list/WaitingList.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTab,
    BTabs,
    BDropdown,
    BDropdownItem,

    AllBooking,
    PendingPayment,
    PendingApproval,
    ConfirmedList,
    WaitingList,
  },
  data() {
    return {
      allCount: 0,
      pendingPaymentCount: 0,
      pendingApprovalCount: 0,
      confirmedCount: 0,
      waitingListCount: 0,
      currentTabTitle: '',
      pageTabs: [
        {
          title: 'Pending Approval',
          param: 'pending-approval',
          count: 0,
        },
        {
          title: 'Pending Payment',
          param: 'pending-payment',
          count: 0,
        },
        {
          title: 'Waiting List',
          param: 'waiting-list',
          count: 0,
        },
        {
          title: 'Confirmed',
          param: 'confirmed',
          count: 0,
        },
        {
          title: 'All Bookings',
          param: 'all-booking',
          count: 0,
        },
      ],
    }
  },
  watch: {
    $route() {
      const param = this.$route.params.type
      const currentTab = this.pageTabs.find(o => o.param === param)
      if (currentTab) {
        this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
      }
    },
  },
  beforeMount() {
    this.countBookings()
  },

  methods: {
    changeRoute(param) {
      this.$router.replace({
        params: {
          type: param,
        },
        query: {},
      })
    },

    countBookings() {
      this.$http.get('appointment/bookings/counts')
        .then(response => {
          this.allCount = response.data.allCount
          this.pendingPaymentCount = response.data.pendingPaymentCount
          this.pendingApprovalCount = response.data.pendingApprovalCount
          this.confirmedCount = response.data.confirmedCount
          this.waitingListCount = response.data.waitingListCount

          this.pageTabs.forEach(element => {
            if (element.title === 'All Bookings') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.allCount
            } else if (element.title === 'Pending Payment') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.pendingPaymentCount
            } else if (element.title === 'Pending Approval') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.pendingApprovalCount
            } else if (element.title === 'Confirmed') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.confirmedCount
            } else if (element.title === 'Waiting List') {
              // eslint-disable-next-line no-param-reassign
              element.count = this.waitingListCount
            }
          })

          const param = this.$route.params.type

          const currentTab = this.pageTabs.find(o => o.param === param)
          if (currentTab) {
            this.currentTabTitle = `${currentTab.title} (${currentTab.count})`
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  .rounded-number {
    height: 24px;
    width: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    color:#104D9D;
  }
</style>
